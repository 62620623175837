import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { InstagramEmbed } from "react-social-media-embed";
import Stack from "@mui/material/Stack";

const Stories = () => {
  return (
    <Container id="stories" sx={{ py: { xs: 4, sm: 4 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={5}>
          <Typography variant="h5">Recent</Typography>
          <Stack spacing={2}>
            <Typography
              variant="h3"
              style={{ color: "#eb1658", fontWeight: "bold" }}
            >
              SUCCESS STORIES
            </Typography>
            <Typography variant="h6" style={{ marginTop: "10px" }}>
              The regional <span style={{ fontWeight: "bold" }}>PYPT</span>{" "}
              round in Pakistan just happened. Eight of our SciBridges coached
              teams were able to secure the top positions, and qualify for the
              nationals!
            </Typography>
            <Typography variant="h6">
              In the pictures below you can see the teams that claimed the:
            </Typography>
            <Typography variant="h6">
              <ul>
                <li>1st position</li>
                <li>3rd position</li>
              </ul>
            </Typography>
            <Typography variant="h6">respectively.</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/Cz1CNhSCrl3/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/Cz1CW7Tiku0/"
              width={350}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Stories;
