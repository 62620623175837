import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Image1 from "../images/iynt-pt/image1.png";
import Image2 from "../images/iynt-pt/image2.png";
import Image3 from "../images/iynt-pt/image3.png";
import Image4 from "../images/iynt-pt/image4.png";
import Image5 from "../images/iynt/image5.png";
import Image6 from "../images/iynt/image6.png";
import Image9 from "../images/iynt/image9.png";

const general = [
  {
    src: Image1,
    alt: "image1",
    url: "https://www.instagram.com/p/CvKgP7_tk3S/",
  },
  {
    src: Image2,
    alt: "image2",
    url: "https://www.instagram.com/p/CvcTxdLtz12/",
  },
  {
    src: Image3,
    alt: "image3",
    url: "https://www.instagram.com/p/CvhOO2bti3U/",
  },
  {
    src: Image4,
    alt: "image4",
    url: "https://www.instagram.com/p/Cv9lg7itXhL/",
  },
  {
    src: Image5,
    alt: "image5",
    url: "https://www.instagram.com/p/CxXnsnOrl5X/?img_index=1",
  },
  {
    src: Image6,
    alt: "image6",
  },
];
const years = [
  {
    src: Image9,
    alt: "image9",
    url: "https://www.instagram.com/p/Cx-F7WXO3tw/",
  },
];

export default function IYNT() {
  return (
    <Box
      id="hero"
      sx={() => ({
        width: "100%",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          pt: { xs: 9, sm: 12 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography variant="general" style={{ color: "#eb1658" }}>
          General
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: "25px" }}>
          {general.map((image, index) => (
            <Grid
              item
              xs={6}
              sm={3}
              key={index}
              sx={{
                transition: "transform .2s",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <Link
                target="_blank"
                to={image.url}
                style={{ textDecoration: "none" }}
              >
                <Paper
                  sx={{
                    aspectRatio: "546/648",
                    overflow: "hidden",
                    borderRadius: "25px",
                    position: "relative",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                >
                  <img src={image.src} alt={`grid-item-${index}`} />
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Typography variant="general" style={{ color: "#eb1658" }}>
          2023-2024
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: "25px" }}>
          {years.map((image, index) => (
            <Grid
              item
              xs={6}
              sm={3}
              key={index}
              sx={{
                transition: "transform .2s",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <Link
                target="_blank"
                to={image.url}
                style={{ textDecoration: "none" }}
              >
                <Paper
                  sx={{
                    aspectRatio: "546/564",
                    overflow: "hidden",
                    borderRadius: "25px",
                    position: "relative",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                >
                  <img src={image.src} alt={`grid-item-${index}`} />
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
