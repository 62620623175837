import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { InstagramEmbed } from "react-social-media-embed";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Hidden from "@mui/material/Hidden";

export default function Ambassadors() {
  return (
    <Box
      id="hero"
      sx={() => ({
        width: "100%",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          pt: { xs: 9, sm: 12 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack justifyContent="center">
          <Typography variant="h4">Meet our</Typography>
          <Stack direction={{ sm: "column", md: "row" }}>
            <Typography variant="h2" style={{ color: "#eb1658" }}>
              AMBASSADORS
            </Typography>
            <Hidden smDown>
              <ArrowForwardIcon style={{ fontSize: "60" }} />
            </Hidden>
            <Typography variant="h3">11 COUNTRIES</Typography>
            <Stack>
              <Typography variant="countries">
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Greece
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Switzerland
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Pakistan
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  U.K.
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Mexico
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Romania
                </span>
              </Typography>
              <Typography variant="countries">
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Iran
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Kyrgyzstan
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Kazakhstan
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Bulgaria
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>|</span>{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Jordan
                </span>{" "}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/Cv5lBsiNVFp/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/Cv7na-otdc1/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CwATtViN2k9/"
              width={350}
            />
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/CwAUwLxN15O/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CwAV0Vqt5Bl/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CwAXF77NUGt/"
              width={350}
            />
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/CwAYJNwt5nm/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CwAZWjcNZqU/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CwCp9T5Na0I/"
              width={350}
            />
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/CwCqWonNJ3v/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CwCr5_8tQ6x/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CwCuOjhtJfe/"
              width={350}
            />
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/Cx3F7Futmrb/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/Cx3D2hANMli/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CydXXhWN8PD/"
              width={350}
            />
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/CwCvlkVNP7o/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CxgoaR3NbO5/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/CxgpmbCNQ9E/"
              width={350}
            />
          </Stack>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <InstagramEmbed
              url="https://www.instagram.com/p/Cx2_XjhNxQ-/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/Cx2_uGbNHwK/"
              width={350}
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/Cx3AlAftcRP/"
              width={350}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
