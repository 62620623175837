import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import IYPT from "../images/rectangleIYPT.png";
import IYNT from "../images/rectangleIYNT.png";

export default function Rectangles() {
  return (
    <Box
      id="hero"
      sx={() => ({
        width: "100%",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 15, sm: 15 },
          pb: { xs: 8, sm: 20 },
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              transition: "transform .2s",
              "&:hover": {
                transform: "scale(1.03)",
              },
            }}
          >
            <Link to="/iynt" style={{ textDecoration: "none" }}>
              <Paper
                sx={{
                  aspectRatio: "1303/481",
                  overflow: "hidden",
                  borderRadius: { xs: "25px", sm: "40px" },
                  position: "relative",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={IYNT} alt="iynt" />
              </Paper>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              transition: "transform .2s",
              "&:hover": {
                transform: "scale(1.03)",
              },
            }}
          >
            <Link to="/iypt" style={{ textDecoration: "none" }}>
              <Paper
                sx={{
                  aspectRatio: "2606/962",
                  overflow: "hidden",
                  borderRadius: { xs: "25px", sm: "40px" },
                  position: "relative",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={IYPT} alt="iypt" />
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
