import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Image1 from "../images/competitions/image1.png";
import Image2 from "../images/competitions/image2.png";
import Image3 from "../images/competitions/image3.png";
import Image4 from "../images/competitions/image4.png";
import Image5 from "../images/competitions/image5.png";
import Image6 from "../images/competitions/image6.png";
import Image7 from "../images/competitions/image7.png";
import Image8 from "../images/competitions/image8.png";
import Image9 from "../images/competitions/image9.png";
import Image10 from "../images/competitions/image10.png";
import Image11 from "../images/competitions/image11.png";
import Image12 from "../images/competitions/image12.png";
import Image13 from "../images/competitions/image13.png";
import Image14 from "../images/competitions/image14.png";
import Image15 from "../images/competitions/image15.png";
import Image16 from "../images/competitions/image16.png";
import Image17 from "../images/competitions/image17.png";
import Image18 from "../images/competitions/image18.png";
import Image19 from "../images/competitions/image19.png";
import Image20 from "../images/competitions/image20.png";
import Image21 from "../images/competitions/image21.png";
import Image22 from "../images/competitions/image22.png";
import Image23 from "../images/competitions/image23.png";
import Image24 from "../images/competitions/image24.png";
import Image25 from "../images/competitions/image25.png";
import Image26 from "../images/competitions/image26.png";

const international = [
  {
    src: Image1,
    alt: "image1",
    url: "https://www.iynt.org/",
  },
  {
    src: Image2,
    alt: "image2",
    url: "https://www.iypt.org/",
  },
  {
    src: Image3,
    alt: "image3",
    url: "https://www.ijsoweb.org/",
  },
  {
    src: Image4,
    alt: "image4",
    url: "https://www.ibo-info.org/en/",
  },
  {
    src: Image5,
    alt: "image5",
    url: "https://www.ioaastrophysics.org/",
  },
  {
    src: Image6,
    alt: "image6",
    url: "https://moschampionship.certiport.com/",
  },
  {
    src: Image7,
    alt: "image7",
    url: "https://www.ichosc.org/",
  },
  {
    src: Image8,
    alt: "image8",
    url: "https://ioinformatics.org/",
  },
  {
    src: Image9,
    alt: "image9",
    url: "https://www.imo-official.org/",
  },
];
const europe = [
  {
    src: Image10,
    alt: "image10",
    url: "https://research-and-innovation.ec.europa.eu/funding/funding-opportunities/eucys_en",
  },
  {
    src: Image11,
    alt: "image11",
    url: "https://www.eoes.science/",
  },
  {
    src: Image12,
    alt: "image12",
    url: "https://ejoi2024.gov.md/",
  },
];
const balkan = [
  {
    src: Image13,
    alt: "image13",
    url: "https://jboi2023.cs.org.mk/",
  },
  {
    src: Image14,
    alt: "image14",
    url: "https://balkanphysicalunion.info/?page_id=657",
  },
  {
    src: Image15,
    alt: "image15",
    url: "https://boi2023.zotks.si/",
  },
];
const greece = [
  {
    src: Image16,
    alt: "image16",
    url: "https://iynt.eef.gr/",
  },
  {
    src: Image17,
    alt: "image17",
    url: "https://eef.gr/news-blog.php?cat=50",
  },
  {
    src: Image18,
    alt: "image18",
    url: "https://eef.gr/news-blog.php?cat=49",
  },
  {
    src: Image19,
    alt: "image19",
    url: "https://www.eef.gr/articles/theloume-peiramata-2023",
  },
  {
    src: Image20,
    alt: "image20",
    url: "http://www.hms.gr/?q=competitions/home",
  },
  {
    src: Image21,
    alt: "image21",
    url: "https://www.eex.gr/news/diagonismoi",
  },
  {
    src: Image22,
    alt: "image22",
    url: "http://www.pdp.gr/",
  },
  {
    src: Image23,
    alt: "image23",
    url: "https://pdbio.pev.gr/",
  },
  {
    src: Image24,
    alt: "image24",
    url: "https://contest.astronomos.gr/",
  },
  {
    src: Image25,
    alt: "image25",
    url: "https://edu.klimaka.gr/drasthriothtes/diagwnismoi/1943-diagwnismos-newn-episthmonwn",
  },
  {
    src: Image26,
    alt: "image26",
    url: "http://www.kangaroo.gr/",
  },
];
export default function Competitions() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  let spacing;
  if (isSmallScreen) {
    spacing = 2;
  } else if (isMediumScreen) {
    spacing = 1;
  } else {
    spacing = 1;
  }
  return (
    <Box
      id="hero"
      sx={() => ({
        width: "100%",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          pt: { xs: 9, sm: 12 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography variant="general" style={{ color: "#eb1658" }}>
          International
        </Typography>
        <Grid container spacing={spacing}>
          {international.map((image, index) => (
            <Grid
              item
              xs={6}
              sm={2.4}
              key={index}
              sx={{
                transition: "transform .2s",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <Link
                target="_blank"
                to={image.url}
                style={{ textDecoration: "none" }}
              >
                <Paper
                  sx={{
                    aspectRatio: "546/648",
                    overflow: "hidden",
                    borderRadius: "25px",
                    position: "relative",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                >
                  <img src={image.src} alt={`grid-item-${index}`} />
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Typography variant="general" style={{ color: "#eb1658" }}>
          Europe
        </Typography>
        <Grid container spacing={spacing}>
          {europe.map((image, index) => (
            <Grid
              item
              xs={6}
              sm={2.4}
              key={index}
              sx={{
                transition: "transform .2s",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <Link
                target="_blank"
                to={image.url}
                style={{ textDecoration: "none" }}
              >
                <Paper
                  sx={{
                    aspectRatio: "546/648",
                    overflow: "hidden",
                    borderRadius: "25px",
                    position: "relative",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                >
                  <img src={image.src} alt={`grid-item-${index}`} />
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Typography variant="general" style={{ color: "#eb1658" }}>
          Balkan
        </Typography>
        <Grid container spacing={spacing}>
          {balkan.map((image, index) => (
            <Grid
              item
              xs={6}
              sm={2.4}
              key={index}
              sx={{
                transition: "transform .2s",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <Link
                target="_blank"
                to={image.url}
                style={{ textDecoration: "none" }}
              >
                <Paper
                  sx={{
                    aspectRatio: "546/648",
                    overflow: "hidden",
                    borderRadius: "25px",
                    position: "relative",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                >
                  <img src={image.src} alt={`grid-item-${index}`} />
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Typography variant="general" style={{ color: "#eb1658" }}>
          Greece
        </Typography>
        <Grid container spacing={spacing}>
          {greece.map((image, index) => (
            <Grid
              item
              xs={6}
              sm={2.4}
              key={index}
              sx={{
                transition: "transform .2s",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <Link
                target="_blank"
                to={image.url}
                style={{ textDecoration: "none" }}
              >
                <Paper
                  sx={{
                    aspectRatio: "546/648",
                    overflow: "hidden",
                    borderRadius: "25px",
                    position: "relative",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                >
                  <img src={image.src} alt={`grid-item-${index}`} />
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
