import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { InstagramEmbed } from "react-social-media-embed";
import Stack from "@mui/material/Stack";

export default function Impact() {
  return (
    <Box
      id="hero"
      sx={() => ({
        width: "100%",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          pt: { xs: 9, sm: 12 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography variant="h5">Community</Typography>
        <Typography style={{ color: "#eb1658" }} variant="h2">
          IMPACT
        </Typography>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          justifyContent="center"
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/CxoXQxWspti/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/Cwz7JX7MeXS/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/Cwh5kyKrKTq/"
            width={350}
          />
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          justifyContent="center"
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/CxDgmE7qT0a/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CxDgYyPK_6O/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C0J96ImCyfD/"
            width={350}
          />
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          justifyContent="center"
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/Cx_DJ2wLq49/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CwhzpTwrRaK/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/Cy3a6Wfiks_/"
            width={350}
          />
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          justifyContent="center"
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/C07QblXr71Q/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/Cx_EajeAKNQ/"
            width={350}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C0J982oizkY/"
            width={350}
          />
        </Stack>
      </Container>
    </Box>
  );
}
