import * as React from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppBar from "./components/AppBar.js";
import Hero from "./components/Hero.js";
import Footer from "./components/Footer.js";
import getTheme from "./getTheme.js";
import About from "./components/Aboutus.js";
import Tree from "./components/Tree.js";
import Impact from "./pages/impact.js";
import Stories from "./components/Stories.js";
import Ambassadors from "./pages/Ambassadors.js";
import IYPT from "./pages/IYPT.js";
import IYNT from "./pages/IYNT.js";
import ScrollToTop from "./components/scrollToTop.js";
import Rectangles from "./pages/Rectangles.js";
import Opportunities from "./pages/Opportunities.js";
import Competitions from "./pages/Competitions.js";

export default function SciBridges() {
  const [mode, setMode] = React.useState(
    () => Cookies.get("colorMode") || "light"
  );
  const theme = createTheme(getTheme(mode));

  useEffect(() => {
    Cookies.set("colorMode", mode);
  }, [mode]);

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <AppBar mode={mode} toggleColorMode={toggleColorMode} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Box sx={{ bgcolor: "background.default" }}>
                  <Divider />
                  <About />
                  <Divider />
                  <Stories />
                  <Divider />
                  <Tree />
                </Box>
              </>
            }
          />
          <Route path=".impact" element={<Impact />} />
          <Route path=".ambassadors" element={<Ambassadors />} />
          <Route path="/iypt-nt" element={<Rectangles />} />
          <Route path="/iypt" element={<IYPT />} />
          <Route path="/iynt" element={<IYNT />} />
          <Route path="/opportunities" element={<Opportunities />} />
          <Route
            path="/opportunities/competitions"
            element={<Competitions />}
          />
          <Route path="/summer-schools" />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}
