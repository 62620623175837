import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Competitions from "../images/comp.png";
import Schools from "../images/sumschool.png";

export default function Opportunities() {
  return (
    <Box
      id="hero"
      sx={() => ({
        width: "100%",
      })}
    >
      <Container
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 15, sm: 15 },
          pb: { xs: 8, sm: 20 },
        }}
      >
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid
            item
            xs={6}
            sm={2}
            sx={{
              transition: "transform .2s",
              "&:hover": {
                transform: "scale(1.03)",
              },
            }}
          >
            <Link to="competitions" style={{ textDecoration: "none" }}>
              <Paper
                sx={{
                  aspectRatio: "710/809",
                  overflow: "hidden",
                  position: "relative",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={Competitions} alt="competitions" />
              </Paper>
            </Link>
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
            sx={{
              transition: "transform .2s",
              "&:hover": {
                transform: "scale(1.03)",
              },
            }}
          >
            <Link to="/summer-schools" style={{ textDecoration: "none" }}>
              <Paper
                sx={{
                  aspectRatio: "710/809",
                  overflow: "hidden",
                  position: "relative",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={Schools} alt="iypt" />
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
