import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import vertical from "../images/vertical.png";
import horizontal from "../images/horizontal.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function ResponsiveTypography() {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Typography
      variant={isMediumScreen ? "h1" : "h4"}
      style={{ color: "#eb1658" }}
    >
      INTERNATIONALLY
    </Typography>
  );
}

export default function Tree() {
  return (
    <Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 7, sm: 10 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography id="tree" variant="h5" textAlign="center">
          6 CHAPTERS + 6 SUB-CHAPTERS
        </Typography>
        <ResponsiveTypography />
        <Box
          sx={(theme) => ({
            mt: { xs: 4, sm: 5 },
            alignSelf: "center",
            height: { xs: "auto", sm: "0px" },
            pt: { xs: "193%", sm: "52.12%" },
            width: "100%",
            backgroundImage: {
              xs: `url(${vertical})`,
              sm: `url(${horizontal})`,
            },
            backgroundSize: { xs: "cover", sm: "contain" },
            backgroundRepeat: "no-repeat",
            borderRadius: "15px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#eb1658", 0.5)
                : alpha("#eb1658", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#eb1658", 0.2)}`
                : `0 0 24px 12px ${alpha("#eb1658", 0.2)}`,
          })}
        />
      </Container>
    </Box>
  );
}
