import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Copyright() {
  return (
    <Stack>
      <Typography variant="body2" color="text.secondary" mt={1}>
        {"Copyright © "}
        <Link href="https://europa.eu/youreurope/business/running-business/intellectual-property/copyright/index_en.htm#:~:text=Information%20for%20businesses-,Your%20rights,work%20public%20or%20reproduce%20it.">
          SciBridges&nbsp;
        </Link>
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Managed by{" "}
        <Link href="https://github.com/eleutheriadis">
          Nikos Eleutheriadis&nbsp;
        </Link>
      </Typography>
    </Stack>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          {/* <Link color="text.secondary" href="#">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Terms of Service
          </Link> */}
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/eleutheriadis"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <GitHubIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://instagram.com/scibridges"
            aria-label="Insta"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="mailto:info@scibridges.org"
            aria-label="Email"
            sx={{ alignSelf: "center" }}
          >
            <EmailIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://discord.gg/Fe62cjQ698"
            aria-label="Discord"
            sx={{ alignSelf: "center" }}
          >
            <FontAwesomeIcon icon={faDiscord} />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://gr.linkedin.com/company/scibridges"
            aria-label="Discord"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
