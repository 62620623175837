import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import pattern from "../images/pattern.png";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const About = () => {
  return (
    <Container id="about" sx={{ py: { xs: 4, sm: 4 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={5}>
          <Typography variant="h5">About</Typography>
          <Typography variant="h2" style={{ color: "#eb1658" }}>
            SciBridges
          </Typography>
          <Typography variant="h4"> Our Mission</Typography>
          <Typography variant="about">
            SciBridges is an international educational, non-​profit,
            social-impact organisation whose mission is:
          </Typography>
          <Typography variant="about">
            <ul>
              <li style={{ marginTop: "10px" }}>
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Bridging science
                </span>{" "}
                and{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  societal understanding,
                </span>
              </li>
              <li style={{ marginTop: "10px" }}>
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Democratizing access
                </span>{" "}
                for students worldwide{" "}
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  to international science tournaments
                </span>
                , such as ​IYPT (International Young Physicists' Tournament)
                ​and IYNT (International Young Naturalists' ​Tournament),
              </li>
              <li
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  listStylePosition: "outside",
                }}
              >
                <span style={{ color: "#eb1658", fontWeight: "bold" }}>
                  Creating a safe space
                </span>{" "}
                for current and former ​participants in competitions like IYPT
                and IYNT to ​make friends, share experiences, and connect.
              </li>
            </ul>
          </Typography>
          <Box sx={{ width: { sm: "100%", md: 600 } }}>
            <Typography variant="about">
              For the past year, we have been working tirelessly, creating
              educational ​content, organizing national workshops, school
              visits, interviews with ​international judges, overseeing
              students' research, and mentoring teams ​participating in national
              science tournaments. All of this is aimed at ​empowering the next
              generation of science enthusiasts to overcome the belief ​that
              they don't have what it takes to succeed.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
        >
          <Box
            sx={{
              m: "auto",
              width: 1000,
              height: 700,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain", // Increase this value to make the image bigger
              backgroundImage: `url(${pattern})`,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
